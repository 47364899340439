document.addEventListener("DOMContentLoaded", function () {
  var searchButton = document.getElementById("search-button");

  if (searchButton) {
    searchButton.addEventListener("click", function (event) {
      event.preventDefault(); // Impede o envio padrão do formulário

      var citySlugInput = document.querySelector("input[name='city_slug']");
      var selectedCitySlug = citySlugInput ? citySlugInput.value : null; // Agora o valor é o slug, já formatado

      if (selectedCitySlug) {
        var cityUrl = "/cities/" + encodeURIComponent(selectedCitySlug) + "/boardgames"; // Usar o slug diretamente
        console.log("Redirecionando para: " + cityUrl); // Debug no console
        window.location.href = cityUrl; // Redireciona para a URL da cidade
      } else {
        alert("Por favor, selecione uma cidade.");
      }
    });
  } else {
    console.error("Botão de busca não encontrado.");
  }
});
