import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select"

export default class extends Controller {
  static values = {
    url: String,
    valueField: { type: String, default: "slug" },
    labelField: { type: String, default: "name" },
    submitOnChange: Boolean
  }

  connect() {
    if (this.hasUrlValue) {
      this.initializeTomSelect()
    } else {
      this.select = new TomSelect(this.element)
    }
  }

  disconnect() {
    this.select.destroy()
  }

  initializeTomSelect() {
    const url = `${this.urlValue}`

    const fetchData = (query, callback) => {
      fetch(`${url}?query=${query}`)
        .then(response => response.json())
        .then(data => callback(data))
        .catch(() => callback())
    }

    const options = {
      valueField: this.valueFieldValue,
      labelField: this.labelFieldValue,
      searchField: this.labelFieldValue,
      placeholder: "Selecione uma cidade",
      preload: true,
      load: fetchData,
      onChange: this.updateCitySlug.bind(this)
    }

    this.select = new TomSelect(this.element, options)
  }

  updateCitySlug(value) {
    const form = this.element.closest("form")
    let citySlugInput = form.querySelector("input[name='city_slug']")
    if (!citySlugInput) {
      citySlugInput = document.createElement("input")
      citySlugInput.type = "hidden"
      citySlugInput.name = "city_slug"
      form.appendChild(citySlugInput)
    }
    citySlugInput.value = value
  }
}
