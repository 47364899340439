import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="see-more"
export default class extends Controller {
  static values = {
    seeMore: { type: String, default: "See more" },
    seeLess: { type: String, default: "See less" },
    cssFramework: { type: String, default: "bootstrap" },
  };

  static targets = ["toggler", "content", "liItem", "multiContent"];

  connect() {
    console.log("Connected to see-more controller");
  }

  toggle() {
    this.toggleContentVisibility();
    this.toggleLiItemsVisibility();
    this.updateTogglerText();
  }

  toggleContentVisibility() {
    if (!this.hasContentTarget && !this.hasMultiContentTarget) return;

    if (this.hasContentTarget) {
      this.toggleElement(this.contentTarget);
    }

    if (this.hasMultiContentTarget) {
      this.multiContentTargets.forEach((content) => this.toggleElement(content));
    }
  }

  toggleLiItemsVisibility() {
    if (!this.hasLiItemTarget) return;

    this.liItemTargets.forEach((li) => this.toggleElement(li));
  }

  updateTogglerText() {
    const isSeeLess = this.togglerTarget.innerText === this.seeLessValue;

    this.togglerTarget.innerText = isSeeLess
      ? this.seeMoreValue
      : this.seeLessValue;

    if (isSeeLess) {
      this.scrollToTop();
    }
  }

  toggleElement(element) {
    element.classList.toggle(this.hiddenClass);
  }

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  // Getter for CSS hidden class based on the selected framework
  get hiddenClass() {
    return this.cssFrameworkValue === "bootstrap" ? "d-none" : "hidden";
  }
}
