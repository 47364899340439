import { Controller } from "@hotwired/stimulus";
import L from "leaflet";

export default class extends Controller {
  static values = {
    storesUrl: String,
    zoom: Number,
  };

  connect() {
    // Coordenadas iniciais vindas dos valores definidos no HTML
    const zoomLevel = this.zoomValue || 13; // Zoom default

    // Cria o mapa na div
    const map = L.map(this.element);

    // Adiciona a camada de tiles do OpenStreetMap
    L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
      attribution:
        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    }).addTo(map);

    // Get stores from the API and add them to the map
    fetch(this.storesUrlValue)
      .then((response) => response.json())
      .then((stores) => {
        // Set map view based on stores positions
        if (stores.length === 1) {
          // Centralizes the map on the only store
          const [latitude, longitude] = [
            stores[0].latitude,
            stores[0].longitude,
          ];
          map.setView([latitude, longitude], zoomLevel);
        } else if (stores.length > 1) {
          // Adjust the map to fit all stores
          const bounds = stores.map((store) => [
            store.latitude,
            store.longitude,
          ]);
          map.fitBounds(bounds);
        } else {
          // Fallback: Centraliza em São Paulo
          map.setView([-23.5505, -46.6333], zoomLevel);
        }

        // Add a marker for each store
        stores.forEach((store) => {
          // Adds a marker for each store
          const marker = L.marker([store.latitude, store.longitude]).addTo(map);
          marker.bindPopup(`<b>${store.name_for_title}</b>`); // Shows the store name on click
        });
      })
      .catch((error) => console.error("Erro ao carregar as lojas:", error));
  }
}
